

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './runtime-var';

import './dis-debugger';
import { logError } from '../logger/arms-config';
import '../network/axios-init';
import { initJsBrdgeListener } from '../jsBirdge/listen';
import { initPermission } from '../../modules/Permissions/utils/init';
import '../../styles/global.less';
import { initFontSize } from '../fontSize';
// import reportWebVitals from './reportWebVitals';

import '../../locales/init';

import '../network/axios-helper';
import '@/utils/permissions';
import { reg_any } from '../common';
import { checkHost } from './checkHost';
// import './disable-context-menu';

checkHost();
initJsBrdgeListener();
initFontSize();
initPermission();

setTimeout(() => {
  const loadingDom = document.getElementById('page_loading');
  if (loadingDom) {
    logError('20秒白屏');
  }
}, 20 * 1000);




// 补丁，使用键盘快捷键从富文本中粘贴出来后，会带有富文本的标记，全局监听事件 并重写数据
setTimeout(() => {
  window.addEventListener('copy', (e) => {
    navigator.clipboard.readText().then(text => {
      navigator.clipboard.writeText(text.replaceAll(reg_any, ''))
        .then(() => {
          console.log('文本已复制到剪切板');
        })
        .catch(err => {
          console.error('无法复制文本: ', err);
        });
    });
  });
}, 2000);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
